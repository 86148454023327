<template>
  <div>
    <div class="help el-icon-search">
      <a
        href="https://vm98s155hg.32zuf20d.com/976636547a37963693560554jkfle-keli746560376454417ca3eeb9774485459fbb736e19f8979e105a8800f918ebdfc6"
        >帮助
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  width: 100px;
  height: 50px;
  line-height: 50px;
  background-color: #00be40;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>